// node modules
import { createStore } from "redux";

// local files
import rootReducer from "./reducer";

export default createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
