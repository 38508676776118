// node modules
import React from "react";
import { connect } from "react-redux";
import checkIsMac from "@letea/functions/checkIsMac";
import checkIsiOS from "@letea/functions/checkIsiOS";
import checkIsAndroid from "@letea/functions/checkIsAndroid";
import checkIsWindows from "@letea/functions/checkIsWindows";
import Clipboard from "@letea/react/components/Clipboard";

// local files
import { Wrapper, FontFace, Example, Info, Link } from "./FontListItem.style";

const DEFAULT_STRING =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+<>?";

const RenderBuiltIn = () => {
  return (
    <>
      {", "}
      {"Built In"}
    </>
  );
};

const RenderSource = ({ url = "", name = "" }) => {
  return (
    <>
      {", "}
      <Link href={url} target="_blank">
        {name}
      </Link>
    </>
  );
};

const RenderCopyCode = ({ fontFamily = "", url = "" }) => {
  const text = `@font-face {
  font-family: "${fontFamily}";
  src: url("${url}");
}

.example {
  font-family: "${fontFamily}";
}`.trim();
  return (
    <>
      {", "}
      <Clipboard
        text={text}
        onSuccess={() => {
          alert("Copy Success.");
        }}
      >
        <Link>Copy Code</Link>
      </Clipboard>
    </>
  );
};

const FontListItem = ({
  text = DEFAULT_STRING,
  name = "",
  weight = "",
  format = "",
  sourceName = "",
  sourceUrl = "",
  enableCopyCode = false,
  fontSize = 32
}) => {
  const isBuiltIn = format.indexOf("built-in") !== -1;
  const hasBuiltInFont =
    isBuiltIn &&
    ((checkIsMac() && format.indexOf("macos") !== -1) ||
      (checkIsiOS() && format.indexOf("ios") !== -1) ||
      (checkIsAndroid() && format.indexOf("android") !== -1) ||
      (checkIsWindows() && format.indexOf("windows") !== -1));
  const hasFontListItem = !isBuiltIn || hasBuiltInFont;
  const hasSource = sourceName && sourceUrl;

  const fontFamily = !isBuiltIn ? `${name}-${weight}` : name;
  const fontFilePath = !isBuiltIn ? `fonts/${fontFamily}.${format}` : "";
  const fontFileLink =
    enableCopyCode && !isBuiltIn ? `${location.origin}/${fontFilePath}` : "";
  const hasCopyLink = !!fontFileLink;

  return hasFontListItem ? (
    <Wrapper>
      {!isBuiltIn && <FontFace fontFamily={fontFamily} src={fontFilePath} />}
      <Example style={{ fontSize, fontFamily: `"${fontFamily}"` }}>
        {text}
      </Example>
      <Info>
        {name}
        {", "}
        {weight}
        {hasBuiltInFont && <RenderBuiltIn />}
        {hasSource && <RenderSource url={sourceUrl} name={sourceName} />}
        {hasCopyLink && (
          <RenderCopyCode fontFamily={fontFamily} url={fontFileLink} />
        )}
      </Info>
    </Wrapper>
  ) : null;
};

export default connect((state) => {
  return {
    text: state.app.text || DEFAULT_STRING,
    fontSize: state.app.fontSize,
    enableCopyCode: state.app.enableCopyCode
  };
})(FontListItem);
