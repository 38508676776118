// node modules
import { combineReducers } from "redux";
import getParametersFromURL from "@letea/functions/getParametersFromURL";

// local modules
import data from "src/data";

// local files
import { SET_TEXT, SET_KEYWORDS, SET_FONT_SIZE } from "../action";

const initialState = {
  data,
  enableCopyCode: !!getParametersFromURL(location.href).aha,
  fontSize: 32,
  keywords: "",
  text: ""
};

function app(state = initialState, action) {
  switch (action.type) {
    case SET_TEXT:
      return {
        ...state,
        ...{
          text: action.text
        }
      };
    case SET_KEYWORDS:
      return { ...state, ...{ keywords: action.keywords } };
    case SET_FONT_SIZE:
      return { ...state, ...{ fontSize: action.fontSize } };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  app
});

export default rootReducer;
