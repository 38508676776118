export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_TEXT = "SET_TEXT";
export const SET_FONT_SIZE = "SET_FONT_SIZE";

export function setText(text) {
  return { type: SET_TEXT, text };
}

export function setKeywords(keywords = "") {
  return {
    type: SET_KEYWORDS,
    keywords
  };
}

export function setFontSize(fontSize = 32) {
  return {
    type: SET_FONT_SIZE,
    fontSize
  };
}
