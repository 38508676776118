export const useDataFilter = ({ data = [], keywords = "" }) => {
  return keywords
    ? data.filter((item) => {
        const { name, weight } = item;
        const sourceName = item?.source?.name || "";
        const keywordList = keywords.split(" ");
        let isMatch = false;

        for (let keyword of keywordList) {
          const lowerCasedKeywords = keyword.toLowerCase();
          if (
            keyword !== "" &&
            (name.toLowerCase().indexOf(lowerCasedKeywords) !== -1 ||
              weight.toLowerCase().indexOf(lowerCasedKeywords) !== -1 ||
              sourceName.toLowerCase().indexOf(lowerCasedKeywords) !== -1)
          ) {
            isMatch = true;
            break;
          }
        }

        return isMatch;
      })
    : data;
};
