// node modules
import styled, { createGlobalStyle } from "styled-components";

// local modules
import { COLOR_WHITE } from "src/colors";

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${COLOR_WHITE};
    font-family: "Roboto", "PingFang TC", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Margin = styled.div`
  margin: ${(props) => props.value}px;
`;
