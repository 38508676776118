// node modules
import React from "react";
import { connect } from "react-redux";
import useWindowSize from "@letea/react/hooks/useWindowSize";

// local modules
import { setText, setKeywords, setFontSize } from "src/redux/action";

// local files
import { Wrapper, Title, Input, Select, Button, SimpleSelect } from "./style";

const getFontSizeRange = (minFontSize = 12, maxFontSize = 60) => {
  const fontSizeRange = [];

  for (let i = minFontSize; i <= maxFontSize; i++) {
    fontSizeRange.push(i);
  }

  return fontSizeRange;
};

const Dashboard = ({
  text = "",
  setText = () => {},
  keywords = "",
  setKeywords = () => {},
  fontSize = 32,
  setFontSize = () => {}
}) => {
  const fontSizeRange = getFontSizeRange(12, 60);
  const { windowWidth } = useWindowSize();
  const isMobileController = windowWidth < 768;
  const isDesktopController = !isMobileController;

  return (
    <Wrapper>
      <Title>FONTS</Title>
      {isMobileController && (
        <>
          <Button
            onClick={() => {
              const newKeywords = prompt("Search Font");
              setKeywords(newKeywords);
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              const newText = prompt("Type something");
              setText(newText);
            }}
          >
            Text
          </Button>
          <SimpleSelect
            defaultValue={fontSize}
            onChange={(event) => {
              setFontSize(Number.parseInt(event.target.value));
            }}
          >
            {fontSizeRange.map((item) => (
              <option value={item} key={item}>{`${item}px`}</option>
            ))}
          </SimpleSelect>
        </>
      )}
      {isDesktopController && (
        <>
          <Input
            defaultValue={keywords}
            onChange={(event) => {
              setKeywords(event.target.value);
            }}
            placeholder="Search Font"
          />
          <Input
            defaultValue={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            placeholder="Type something"
          />
          <Select
            defaultValue={fontSize}
            onChange={(event) => {
              setFontSize(Number.parseInt(event.target.value));
            }}
          >
            {fontSizeRange.map((item) => (
              <option value={item} key={item}>{`${item}px`}</option>
            ))}
          </Select>
        </>
      )}
    </Wrapper>
  );
};

export default connect(
  (state) => {
    return {
      text: state.app.text,
      keywords: state.app.keywords,
      fontSize: state.app.fontSize
    };
  },
  { setText, setKeywords, setFontSize }
)(Dashboard);
