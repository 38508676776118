// node modules
import "@letea/css-reset";
import React from "react";

// local modules
import Dashboard from "src/components/Dashboard";
import FontList from "src/components/FontList";

// local files
import { GlobalStyle, Margin } from "./App.style";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Dashboard />
      <Margin value={90} />
      <FontList />
    </>
  );
};

export default App;
