// node modules
import React from "react";
import { connect } from "react-redux";

// local files
import FontListItem from "./FontListItem";
import { Wrapper } from "./style";
import { useDataFilter } from "./hooks";

const FontList = ({ data = [], keywords = "" }) => {
  const filteredData = useDataFilter({
    data,
    keywords
  });

  return (
    <Wrapper>
      {filteredData.map((item) => {
        return (
          <FontListItem
            name={item.name}
            format={item.format}
            weight={item.weight}
            sourceName={item?.source?.name}
            sourceUrl={item?.source?.url}
            key={`${item.name}-${item.weight}`}
          />
        );
      })}
    </Wrapper>
  );
};

export default connect((state) => {
  return { data: state.app.data, keywords: state.app.keywords };
})(FontList);
