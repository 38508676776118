// node modules
import styled, { createGlobalStyle } from "styled-components";

// local modules
import { COLOR_GREEN } from "src/colors";

export const Wrapper = styled.div`
  margin: auto auto 1.5rem;
  & > * {
    font-size: 0.9rem;
    line-height: 1.5;
  }
`;

export const Example = styled.p`
  line-height: 1.35;
  padding: 0.5rem 0;
  word-break: break-all;
`;

export const FontFace = createGlobalStyle`
  @font-face {
    font-family: "${(props) => props.fontFamily}";
    src: url("${(props) => props.src}");
  }
`;

export const Info = styled.div`
  text-align: left;
`;

export const Link = styled.a`
  cursor: pointer;
  font-weight: bolder;
  text-decoration: none;

  &,
  &:visited {
    color: ${COLOR_GREEN};
  }
`;
