// node modules
import styled from "styled-components";

// local modules
import { COLOR_BLACK, COLOR_WHITE } from "src/colors";

export const Wrapper = styled.div`
  background: ${COLOR_WHITE};
  border-bottom: 1px solid ${COLOR_BLACK};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 999;
`;

export const Title = styled.h1`
  display: inline-block;
  margin: auto auto auto 0;
  font-weight: bolder;
  font-size: 22px;
`;

export const Input = styled.input`
  appearance: none;
  border-radius: 1px;
  border: 1px solid ${COLOR_BLACK};
  margin-right: 0.75rem;
  padding: 6px;
`;

export const Select = styled.select`
  border-radius: 1px;
  border-color: ${COLOR_BLACK};
`;

export const Button = styled.div`
  cursor: pointer;
  font-weight: bolder;
  line-height: normal;
  margin-right: 0.75rem;
`;

export const SimpleSelect = styled.select`
  appearance: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
  padding: 0;
`;
