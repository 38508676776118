const data = [
  {
    name: "Library3AM",
    weights: ["Regular"],
    format: "otf",
    source: {
      name: "1001fonts",
      url: "https://www.1001fonts.com/library-3-am-font.html"
    }
  },
  {
    name: "Library3AMSoft",
    weights: ["Regular"],
    format: "otf",
    source: {
      name: "1001fonts",
      url: "https://www.1001fonts.com/library-3-am-font.html"
    }
  },
  {
    name: "TaipeiSansTCBeta",
    weights: ["Light", "Regular", "Bold"],
    format: "ttf",
    source: {
      name: "JT Foundry",
      url: "https://sites.google.com/view/jtfoundry/"
    }
  },
  {
    name: "Hack",
    weights: ["Regular", "Italic", "Bold", "BoldItalic"],
    format: "ttf",
    source: {
      name: "Source Foundry",
      url: "https://sourcefoundry.org/hack/"
    }
  },
  {
    name: "Roboto",
    weights: [
      "Thin",
      "ThinItalic",
      "Light",
      "LightItalic",
      "Regular",
      "Italic",
      "Medium",
      "MediumItalic",
      "Bold",
      "BoldItalic",
      "Black",
      "BlackItalic"
    ],
    format: "ttf",
    source: {
      name: "Google Fonts",
      url: "https://fonts.google.com/specimen/Roboto"
    }
  },
  {
    name: "RobotoCondensed",
    weights: [
      "Light",
      "LightItalic",
      "Regular",
      "Italic",
      "Bold",
      "BoldItalic"
    ],
    format: "ttf",
    source: {
      name: "Google Fonts",
      url: "https://fonts.google.com/specimen/Roboto"
    }
  },
  {
    name: "ChickenMcNuggets",
    weights: ["Regular"],
    format: "ttf",
    source: {
      name: "Mcdtaiwan",
      url: "https://github.com/mcdtaiwan/Chicken_McNuggets"
    }
  },
  {
    name: "Huninn",
    weights: ["Regular"],
    fotmat: "ttf",
    source: {
      name: "justfont",
      url: "https://github.com/justfont/open-huninn-font"
    }
  },
  {
    name: "3DThirteenPixel",
    weights: ["Regular"],
    format: "ttf",
    source: {
      name: "FontStructions",
      url:
        "https://fontstruct.com/fontstructions/show/851388/3d_thirteen_pixel_fonts"
    }
  },
  {
    name: "404NotFont",
    weights: ["Regular"],
    format: "woff2",
    source: {
      name: "3Q.9527.TW",
      url: "https://3q.9527.tw/36"
    }
  },
  {
    name: "Archistico",
    weights: ["Regular", "Bold"],
    format: "ttf",
    source: {
      name: "1001fonts",
      url: "https://www.1001fonts.com/archistico-font.html#license"
    }
  },
  {
    name: "Azoft Sans",
    weights: ["Regular", "Italic", "Bold", "BoldItalic"],
    format: "otf",
    source: {
      name: "1001fonts",
      url: "https://www.1001fonts.com/azoft-sans-font.html#styles"
    }
  },
  {
    name: "SourceHanSerifTC",
    weights: [
      "ExtraLight",
      "Light",
      "Regular",
      "Medium",
      "SemiBold",
      "Bold",
      "Heavy"
    ],
    format: "otf",
    source: {
      name: "思源宋體",
      url: "https://source.typekit.com/source-han-serif/tw/"
    }
  },
  {
    name: "PingFang TC",
    weights: ["Regular"],
    format: "built-in-macos-ios"
  },
  {
    name: "Helvetica",
    weights: ["Regular"],
    format: "built-in-macos-ios"
  },
  {
    name: "Microsoft JhengHei",
    weights: ["Regular"],
    format: "built-in-windows"
  },
  {
    name: "Arial",
    weights: ["Regular"],
    format: "built-in-windows-macos-ios-android"
  },
  {
    name: "Verdana",
    weights: ["Regular"],
    format: "built-in-windows-macos-ios-android"
  }
];

export default data
  .sort((itemA, itemB) => {
    const nameA = itemA.name.toLowerCase();
    const nameB = itemB.name.toLowerCase();

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    return 0;
  })
  .reduce((accumulator, currentValue) => {
    const getDataByWeights = (data = {}) => {
      if (data.weights) {
        return currentValue.weights.map((item) => {
          const newObject = { ...data, weight: item };
          delete newObject.weights;
          return newObject;
        });
      }
      return [];
    };

    if (Array.isArray(accumulator)) {
      return [].concat(accumulator, getDataByWeights(currentValue));
    } else {
      return [].concat(
        getDataByWeights(accumulator),
        getDataByWeights(currentValue)
      );
    }
  });
